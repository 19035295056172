/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import React, { useReducer, useEffect, useState, useCallback } from "react"
import PageContext from "./src/context/PageContext"
import { Reducer } from "./src/context/reducers"

let logoutTimer

const App = props => {
    const [token, setToken] = useState(
        JSON.parse(localStorage.getItem("userData"))?.accessToken
    )
    const [tokenExpirationDate, setTokenExpirationDate] = useState()

    const login = useCallback((data, expirationTime) => {
        if (data) {
            setToken(data.accessToken)
            const expiration =
                expirationTime ||
                new Date(new Date().getTime() + 1000 * 60 * 60 * 3)
            setTokenExpirationDate(expiration)
            localStorage.setItem(
                "userData",
                JSON.stringify({
                    accessToken: data.accessToken,
                    usersDataId: data.usersDataId,
                    email: data.email,
                    expirationTime: expiration.toISOString(),
                })
            )
            dispatch({
                type: "SET_USER",
                data: {
                    usersDataId: data.usersDataId,
                },
            })
        }
    }, [])

    const logout = useCallback(() => {
        setToken(null)
        localStorage.removeItem("userData")
        localStorage.removeItem("userDataStatus")
        dispatch({ type: "CLEAR_USER" })
    }, [])

    const initialState = {
        menu: [],
        breadCrumbPath: [],
        isLoggedIn: !!token,
        userData: JSON.parse(localStorage.getItem("userData")),
        token: token,
        login: login,
        logout: logout,
        modal: { open: false },
        searchString: null,
    }

    const [state, dispatch] = useReducer(Reducer, initialState)

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("userData"))
        if (
            storedData &&
            storedData.accessToken &&
            new Date(storedData.expirationTime) > new Date()
        ) {
            login(storedData, new Date(storedData.expirationTime))
        }
    }, [login])

    useEffect(() => {
        if (token && tokenExpirationDate) {
            const remainingTime =
                tokenExpirationDate.getTime() - new Date().getTime()
            logoutTimer = setTimeout(logout, remainingTime)
        } else {
            clearTimeout(logoutTimer)
        }
    }, [token, logout, tokenExpirationDate])

    return (
        <PageContext.Provider value={[state, dispatch]}>
            {props.children}
        </PageContext.Provider>
    )
}

export const wrapPageElement = ({ element }) => <App>{element}</App>
