import { Link } from "gatsby"
import React from "react"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"
import { StaticImage } from "gatsby-plugin-image"
import { ColorButton } from "./layout/ColorButton"

const navigation = {
    social: [
        {
            name: "Facebook",
            href: "https://www.facebook.com/patrakrymska",
            icon: props => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: "Instagram",
            href: "https://instagram.com/patrakrymska",
            icon: props => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
}

const links = [
    {
        title: "Účetnictví",
        menuName: "Účetnictví",
        url: "sluzby-ucetnictvi/",
    },
    {
        title: "Mzdy",
        menuName: "Mzdy",
        url: "mzdy/",
    },
    {
        title: "Daně, audit",
        menuName: "Daně, audit",
        url: "dane-audit/",
    },
    // {
    //   title: "O nás",
    //   menuName: "O nás",
    //   url: "o-nas/",
    // },
    // {
    //   title: "Reference",
    //   menuName: "Reference",
    //   url: "reference-ucetnich-sluzeb/",
    // },
    {
        title: "Ceník",
        menuName: "Ceník",
        url: "cenik-ucetnictvi/",
    },
    {
        title: "Poptávka",
        menuName: "Poptávka",
        url: "poptavka/",
    },
    {
        title: "Kontakt",
        menuName: "Kontakt",
        url: "kontakt/",
    },
]

export default function Footer() {
    const buttonClass = "  px-3 py-2 flex space-x-2  "

    var pages1 = links.slice(0, 3)
    var pages2 = links.slice(3, 6)
    var pages3 = links.slice(6)

    return (
        <>
            <footer className=" text-white " aria-labelledby="footerHeading">
                <h2 id="footerHeading" className="sr-only">
                    Patička
                </h2>
                <div className="max-w-7xl mx-auto py-12 px-8 pb-20 lg:pb-auto  lg:py-16 lg:px-20 bg-neutral-800 bg-opacity-50">
                    <div className=" grid xl:grid-cols-4 gap-8 xl:gap-8">
                        <div className="grid md:flex md:justify-between  xl:col-span-3 "></div>
                        <div className="flex justify-start lg:justify-end"></div>
                    </div>
                    <div className=" grid md:grid-cols-2 gap-4  pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
                        <div className="order-2 lg:order-1">
                            <StaticImage
                                src="../images/patra-logo.svg"
                                alt="Patra"
                                className="w-20 md:w-40 ml-2"
                            />
                        </div>
                    </div>
                    <div className="mt-8 border-t border-gray-400 pt-8 md:flex md:items-center md:justify-between">
                        <div className="flex justify-between space-y-4 md:space-y-0 md:space-x-8 md:order-2 ">
                            <div className="grid gap-4 md:flex md:space-x-8">
                                <div className="flex space-x-2  ">
                                    <PhoneIcon className="w-6 h-6" />
                                    <span>+420 774 774 677</span>
                                </div>
                                <div className="flex space-x-2 ">
                                    <MailIcon className="w-6 h-6" />
                                    <a href="mailto:info@patrakrymska.cz">
                                        info@patrakrymska.cz
                                    </a>
                                </div>
                            </div>
                            <div className="md:pl-6 flex space-x-4">
                                {navigation.social.map(item => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className=" hover:text-neutral-300"
                                    >
                                        <span className="sr-only">
                                            {item.name}
                                        </span>
                                        <item.icon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                        <p className="mt-8 text-base  md:mt-0 md:order-1  ">
                            &copy; {new Date().getFullYear()} Krymská komunitní
                            s.r.o.
                        </p>
                    </div>
                </div>
                <div className="   text-neutral-300 grid gap-2 md:flex md:justify-between text-sm py-3 px-8  ">
                    <div className="grid gap-2 lg:gap-10 md:grid-cols-2">
                        <a
                            href="https://www.google.com/recaptcha/about/"
                            target="_blank"
                            className="flex space-x-2 items-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                                />
                            </svg>
                            <span>Chráněno pomocí Google reCAPTCHA</span>
                        </a>

                        <Link
                            to={"/cookies"}
                            className="flex space-x-2 items-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                strokeWidth="1.5"
                                fill="currentColor"
                                className="w-6 h-6"
                            >
                                <circle cx="24" cy="34" r="2" />
                                <circle cx="24" cy="24" r="2" />
                                <circle cx="14" cy="24" r="2" />
                                <path
                                    d="M37.478,19.117l-0.597-1.272l-1.399,0.131C35.322,17.99,35.162,18,35,18c-2.757,0-5-2.243-5-5
		c0-0.162,0.01-0.322,0.024-0.479l0.131-1.399l-1.271-0.598C27.132,9.701,26,7.926,26,6V4h-2C12.972,4,4,12.972,4,24s8.972,20,20,20
		c11.028,0,20-8.972,20-20v-2h-2C40.074,22,38.299,20.868,37.478,19.117z M24,40c-8.822,0-16-7.178-16-16
		c0-8.231,6.249-15.03,14.25-15.905c0.53,2.188,1.875,4.126,3.762,5.389c0.243,4.579,3.926,8.261,8.505,8.504
		c1.262,1.888,3.199,3.231,5.388,3.762C39.03,33.752,32.231,40,24,40z"
                                />
                            </svg>
                            <span>Nastavení cookies</span>
                        </Link>
                    </div>
                    <a
                        href="https://www.emocio.cz/"
                        className="flex lg:space-x-1 items-center  "
                        target="_blank"
                    >
                        <span className="order-2 lg:order-1 ml-2 lg:ml-0  ">
                            vytvořilo <strong>emocio</strong>
                        </span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 162.5 117"
                            strokeWidth="1.5"
                            fill="currentColor"
                            className="w-6 h-6 order-1 lg:order-2"
                        >
                            <path
                                d="M162.5,72.9C148,106.7,97,129.2,60.2,94.3
	C48.8,83.5,42.7,69,44,53.7c3.1-33.4,32.9-51.1,67.8-46c-47.1,0.7-64,31.2-54.5,56.6C69.7,96.9,116.8,120.2,162.5,72.9"
                            />
                            <path
                                d="M90.5,16.8c58.9-14.7,51.2,79.3-85.3,76.2
	C104.7,90.9,133.7,18.5,90.5,16.8"
                            />
                        </svg>
                    </a>
                </div>
            </footer>
        </>
    )
}
