export const SET_MENU = "SET_MENU" 
 
const setMenu = (data, state) => {
  return { ...state, menu: data }
}
 
export const Reducer = (state, action) => {
  switch (action.type) { 
    case "SET_MENU":
      return setMenu(action.data, state)
    case "SET_MODAL":
      return {
        ...state,
        modal: {
          component: action.payload.component,
          params: action.payload.params,
          open: true,
          fullscreen: action.payload.fullscreen,
        },
      } 
    case "SET_USER":
      return {
        ...state,
        isLoggedIn: true,
        userData: action.data,
      }
    case "CLEAR_USER":
      return {
        ...state,
        isLoggedIn: false,
        userData: null,
      }
    case "SET_BREADCRUMB":
      return {
        ...state,
        breadCrumbPath: action.payload.breadCrumbPath,
      }
    case "SET_SEARCHSTRING":
      return {
        ...state,
        searchString: action.payload.searchString,
      }
    case "CLEAR_MODAL":
      return {
        ...state,
        modal: {
          component: null,
          params: null,
          open: false,
        },
      }
    default:
      return state
  }
}
