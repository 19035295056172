import { ArrowRightIcon } from "@heroicons/react/solid"
import React from "react"
export const ColorButton = ({ icon, text, color }) => {
  let bgColor
  if (color === "gray") {
    bgColor = "border-gray-800 bg-[#4F4F4F] hover:bg-gray-700"
  }

  if (!color) {
    bgColor = "border-red-800 bg-[#D2000E] hover:bg-red-800"
  }

  return (
    <>
      <button
        className={
          "flex relative items-center space-x-2 px-4 text-sm font-semibold  py-3 text-white  rounded-md border   hover:text-white  focus:outline-none shadow-md overflow-hidden lg:min-w-[12em] " +
          bgColor
        }
        aria-label={text}
      >
        <span className="flex justify-between items-center w-full">
          {text}
          <ArrowRightIcon className="w-5 h-5 ml-5" />
        </span>
      </button>
    </>
  )
}
