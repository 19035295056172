import React from "react";

export default React.createContext({  
  menu: [],
  breadCrumbPath: [], 
  isLoggedIn: false, 
  token: null,
  userData: null,
  login: () => {},
  logout: () => {},
  modal: { open: false },
  searchString: null,
});
