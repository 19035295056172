import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { Modal } from "./layout/modal"
import { CookieConsent } from "react-cookie-consent"
import PageContext from "../context/PageContext"
import { SET_MENU } from "../context/reducers"
import Header from "./header"
import Footer from "./footer"
import { ChevronUpIcon } from "@heroicons/react/solid"
import { motion } from "framer-motion"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "../client/apollo"

const Layout = ({ children, location }) => {
    const data = useStaticQuery(graphql`
        query Pages {
            scomos {
                pages {
                    id
                    parentId
                    menuName
                    menu
                    url
                    lft
                }
            }
        }
    `)

    // eslint-disable-next-line
    const [context, dispatch] = useContext(PageContext)
    const [showGoTop, setGoTop] = useState(false)

    const handleScrollUp = () => {
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
    }

    const handleScroll = () => {
        if (window.pageYOffset >= 300) {
            setGoTop(true)
        } else {
            setGoTop(false)
        }
    }

    useEffect(() => {
        window.onscroll = () => handleScroll()
    }, []) // IMPORTANT, This will cause react to update depending on change of this value

    useEffect(() => {
        ;(async () => {
            let items = []
            let mainId = 0
            function menuItems() {
                data.scomos.pages.forEach(menuItem => {
                    if (menuItem.parentId === 0) {
                        mainId = menuItem.id
                    }
                    if ([1, 2, 3, 4, 7].includes(menuItem.id)) {
                        items[menuItem.id] = menuItem
                    }
                })
                let newItems = [...items]
                items.forEach(menuItem => {
                    if (items[menuItem.parentId]) {
                        if (!items[menuItem.parentId]["children"]) {
                            newItems[menuItem.parentId]["children"] = []
                        }
                        newItems[menuItem.parentId]["children"][
                            menuItem.lft
                        ] = menuItem
                    }
                })

                let sorted = []
                newItems[mainId]["children"].forEach(newMenuItem => {
                    sorted[newMenuItem.lft] = newMenuItem
                })

                return sorted
            }

            let newMenu = menuItems()
            dispatch({ type: SET_MENU, data: newMenu })
        })()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText={
                    <div className="rounded-md bg-gray-100 px-6 py-2  hover:bg-gray-300">
                        Souhlasím
                    </div>
                }
                buttonWrapperClasses="w-full grid gap-4 lg:gap-2 "
                declineButtonText={
                    <div className="rounded-md bg-gray-100 opacity-80 px-6 py-2 hover:bg-gray-300  ">
                        Nesouhlasím
                    </div>
                }
                enableDeclineButton
                flipButtons={true}
                disableStyles
                overlay={true}
                contentClasses="lg:col-span-2"
                overlayClasses=" bg-gradient-to-b from-transparent to-black  z-[1000] h-[300px]  bottom-0 w-full fixed flex justify-center"
                cookieName="gatsby-gdpr-google-tagmanager"
                containerClasses="fixed transition   bg-gradient-to-br grid lg:grid-cols-3 gap-8 from-red-600 to-red-900  z-[1000] w-auto  lg:w-[700px]    lg:border border-gray-400 p-8 lg:rounded-md shadow-lg  lg:my-10  "
            >
                <div className="text-xl font-bold   text-white mb-3  ">
                    Používáme cookies
                </div>
                <div className=" text-xs text-gray-100">
                    Základní cookies potřebujeme k zajištění správného fungování
                    stránek
                </div>
            </CookieConsent>
            <ApolloProvider client={apolloClient}>
                <div className="h-full min-h-screen bg-black relative overflow-hidden">
                    <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)]  transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] "
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#CE8A39] to-[#422E07] opacity-80 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                            }}
                        />
                    </div>
                    <div
                        className="absolute inset-x-0 left-0  transform-gpu overflow-hidden blur-3xl  "
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(10%+3rem)] aspect-[1155/678] w-[86.125rem] -translate-x-1/6 bg-gradient-to-tr from-[#CE8A39] to-[#422E07] opacity-80 sm:left-0 sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                            }}
                        />
                    </div>
                    <div className="max-w-7xl mx-auto   relative mb-20  ">
                        <main className="relative bg-gradient-to-br from-[#CE8A39] to-[#422E07] text-white">
                            <Header location={location} />
                            {children}
                        </main>
                        <Footer />
                    </div>
                </div>
            </ApolloProvider>
            <motion.div
                className="bg-neutral-700 border fixed bottom-14 right-10 rounded-full z-50   "
                style={{
                    transform: showGoTop ? "none" : "translateY(40px)",
                    opacity: showGoTop ? 1 : 0,
                    transition: "all 0.3s  0.3s",
                }}
            >
                <button
                    type={"button"}
                    onClick={handleScrollUp}
                    className="p-3"
                    aria-label="Nahoru"
                >
                    <ChevronUpIcon className="w-8 h-8 text-white" />
                </button>
            </motion.div>
            <Modal />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
