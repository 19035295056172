import React, { useState } from "react"
import { default as Logo } from "../images/patra-logo.svg"

import { Link } from "gatsby"

import MainMenu from "./layout/MainMenu"

export default function Header({ menu, white, location }) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <div className="absolute h-36 bg-gradient-to-b from-black w-full  z-10">
                <div className=" p-1 hidden lg:block text-white  text-xs z-[150] relative  ">
                    <div className="flex w-full justify-between max-w-7xl mx-auto  px-6">
                        <div className="flex items-center"></div>
                    </div>
                </div>
            </div>
            <div className="absolute z-50 w-full lg:p-5">
                {/* <MainMenu /> */}
            </div>
        </>
    )
}
