import axios from "axios"

const sign = require("jwt-encode")
const secret =
    "hbGciOiJxNTE2MjM5MIUzI1NiIsInR5cCI6ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaNTY3fyWF0IjoDIew56y"
const data = {
    name: "Patra",
}
const jwt = sign(data, secret)

let URL
let API_ROUTE
const STAGE = process.env.NODE_ENV

if (STAGE === "development") {
    URL = "http://localhost/patrakrymska/admin/"
    API_ROUTE = "http://localhost:8001/"
} else {
    URL = "https://admin.patrakrymska.cz/"
    API_ROUTE = "https://api.patrakrymska.cz/"
}

const axiosInstance = axios.create({
    // withCredentials: true,
    baseURL: URL + "public/",
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + jwt,
    },
})

const protectedAxios = () => {
    const userToken = JSON.parse(localStorage.getItem("userData"))
    console.log(userToken.accessToken)
    return axios.create({
        // withCredentials: true,
        baseURL: URL + "api/",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + userToken?.accessToken,
        },
    })
}

export const axiosProtected = protectedAxios
export const CONFIG_APP_API_ROUTE = API_ROUTE
export default axiosInstance
